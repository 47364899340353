@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif;
  color: rgb(29, 29, 31);
}

body {
  background-color: #ddead0;
  min-height: 100vh;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

.navbar {
  background-color: rgba(245, 245, 247, 0.8);
  backdrop-filter: saturate(1.8) blur(20px);
}

.container {
  max-width: 1024px;
}

.card {
  background-color: #f5f5f7;
}

a {
  text-decoration: none;
  color: rgb(29, 29, 31);
}

a:hover {
  color: #06c;
  text-decoration: underline;
}

.card-img-top {
  max-height: 235px;
  object-fit: cover;
  overflow-clip-margin: unset;
  image-rendering: smooth;
}

.ribbon {
  width: 200px;
  padding: 4px;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
  top: 23px;
  left: -60px;
  transform: rotate(-45deg);
  background-color: #e43;
  font-weight: 500;
  letter-spacing: 0.1rem;
}
